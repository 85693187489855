import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

(function () {
  const toastOptions = { delay: 8000 };

  function createToast(message) {
    // Clone the template
    const element = htmx.find("[data-toast-template]").cloneNode(true);

    // Remove the data-toast-template attribute
    delete element.dataset.toastTemplate;

    // Set the CSS class
    element.className += " " + message.tags;

    // Set the text
    // htmx.find(element, "[data-toast-body]").innerText = message.message;

    // Add the new element to the container
    htmx.find("[data-toast-container]").appendChild(element);

    // Show the toast using Bootstrap's API
    // const toast = new bootstrap.Toast(element, toastOptions);
    const toast = Toastify({
      text: message.message,
      className: element.className,
      duration: 3000,
      stopOnFocus: true,
      close: true,
      style: {
        background: "linear-gradient(to right, #00b09b, #96c93d)",
      },
    });
    toast.showToast();
  }

  htmx.on("messages", (event) => {
    event.detail.value.forEach(createToast);
  });

  // Show all existsing toasts, except the template
  htmx.findAll(".toast:not([data-toast-template])").forEach((element) => {
    // const toast = new bootstrap.Toast(element, toastOptions);
    // toast.show();

    const toast = Toastify({
      text: htmx.find(element, "[data-toast-body]").innerText,
      className: element.className,
      duration: 3000,
      stopOnFocus: true,
      close: true,
      style: {
        background: "linear-gradient(to right, #00b09b, #96c93d)",
      },
    });
    toast.showToast();
  });
})();
